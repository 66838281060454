import $ from 'jquery';
import { getClassName, scrollToElm } from 'Util/core';
import { publish } from 'Util/pubsub';

var Validate;

var selectors = {
	form: '.js-validate-form', // Add to form wrapping element
	field: '.js-validate-field', // Add to field holder/wrapper e.g. holds label, input and error message
	input: '[data-val-required][type!="checkbox"]', // Add to input that needs validation
	optionList: '.js-validate-optionlist', // Add to <ul> containing required checkbox/radiobutton list
	submit: '.js-validate-submit', // Add to submit button
	errorMessage: '.js-validate-field-validation', // Add to any displayed error (hidden or shown)
	fieldError: '.has-error' // For styling field holder
};

var $formsToValidate;
var defaultErrorMessage = 'This field is required';

var module = {
	init: function () {

		$formsToValidate = $(selectors.form);

		if ($formsToValidate.length) {
			for (var i = 0; i < $formsToValidate.length; i++) {
				module.initForm($formsToValidate[i]);
			}
		}
	},

	// Initialise each form individually
	// Bind form submission events if there are fields requiring validation
	initForm: function (form) {
		var $thisForm = $(form);

		$thisForm.on('click', selectors.submit, module._processSubmitClick);
		$thisForm.on('submit', { form: $thisForm }, module._onFormSubmission);
	},

	// Hijack submit button click then submit form
	_processSubmitClick: function (event) {
		event.preventDefault();
		$(this).closest(selectors.form).submit();
	},

	// Run on form submission
	// Enacts validation
	// If errors after validation, scroll to first one
	// Binds listender for keyup on required inputs
	// Returns true if form valid
	_onFormSubmission: function (event) {
		var $requiredInputs = event.data.form.find(selectors.input + ',' + selectors.optionList).filter(':visible');

		if ($requiredInputs.length) {

			event.preventDefault();
			var formValid = module.validateForm($requiredInputs);

			$requiredInputs.on('keyup', module.validateInput);
			$requiredInputs.filter('select, [type="checkbox"]').on('change', module.validateInput);
			$requiredInputs.on('change', '[type="checkbox"], [type="radio"]', module.validateInput);

			event.data.form.trigger('customvalidation');

			var $errorFields = event.data.form.find(selectors.fieldError).filter(':visible');

			if ($errorFields.length) {
				scrollToElm($($errorFields[0]), 100);
				publish('/form/validate/clientside/failed', { form: event.data.form, errorFields: $errorFields });
			} else {
				publish('/form/' + event.data.form.attr('id') + '/valid');
				publish('/form/validate/clientside/passed', { form: event.data.form });
			}
		}
	},

	// Validates all required inputs of a form
	// Returns true if number of valid inputs equals number required
	// $requiredInputs = jQuery object - the inputs to validate
	validateForm: function ($requiredInputs) {
		var validCount = 0;
		var requiredLength = $requiredInputs.length;

		for (var i = 0; i < requiredLength; i++) {
			var isValid = module.validateInput.call($requiredInputs[i]);

			if (isValid.valid) {
				validCount++;
			}
		}
		return validCount === requiredLength;
	},

	// Validates a single field
	// Sets error display dependant on validation result
	validateInput: function () {
		var $thisInput = $(this);
		var $thisField = $thisInput.closest(selectors.field);
		var data;

		// validate checklist or radiolist
		if ($thisInput.hasClass(getClassName(selectors.optionList)) || $thisInput.closest(selectors.optionList).length) {
			data = module._validateOptionList($thisField);
		} else {
			// validate required
			data = module._validateRequired($thisInput);
		}
		module.setErrorDisplay($thisField, data);

		return data;
	},

	// Check a checkbox list or radiobutton list for at least one checked input
	_validateOptionList: function ($thisField) {
		var data = {
			valid: true
		};

		if (!$thisField.find('input:checked').length) {
			data.valid = false;
			data.message = $thisField.data('val-required');
		}
		return data;
	},

	// Check a required field for a non-empty string
	// If it has a regex pattern, test against this too
	_validateRequired: function ($thisInput) {
		var data = {
			valid: true
		};

		if ($thisInput.is('[type="checkbox"]')) {
			data.valid = $thisInput.is(':checked');
			data.message = $thisInput.data('val-required');
		} else {
			var value = $.trim($thisInput.val());

			if ($thisInput.is(selectors.input) && value === '') {
				data.valid = false;
				data.message = $thisInput.data('val-required');
			}

			if (data.valid && $thisInput.is('[data-val-regex-pattern]')) {

				if (!new RegExp($thisInput.data('val-regex-pattern')).test(value)) {
					data.valid = false;
					data.message = $thisInput.data('val-regex');
				}
			}
		}1
		return data;
	},

	// Displays or removes error field data.valid value
	setErrorDisplay: function ($thisField, data) {
		var $thisError = $thisField.find(selectors.errorMessage);
		var fieldErrorClass = getClassName(selectors.fieldError);

		if (!data.valid) {
			$thisError.text(data.message || defaultErrorMessage);
			$thisField.addClass(fieldErrorClass);
		} else {
			$thisError.text('');
			$thisField.removeClass(fieldErrorClass);
		}
	}
};

var Validate = {
	init: module.init,
	initForm: module.initForm,
	validateForm: module.validateForm,
	validateInput: module.validateInput,
	setErrorDisplay: module.setErrorDisplay
};

export { Validate };