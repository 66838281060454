import $ from 'jquery';
import { subscribe, unsubscribe, publish } from 'Util/pubsub';

var $nav;
var classes;
var selectors;

var $navItems;
var $navLinks;
var $navToggle;
var elementsInit = false;

var module = {

	init: function ($elm, selectorList, classList) {

		$nav = $elm;
		selectors = selectorList;
		classes = classList;

		module._initElements();
		module._initSubscriptions();
		module._initEvents();
	},

	_initElements: function () {
		if (!elementsInit) {
			$navItems = $nav.find(selectors.navItem);
			$navLinks = $nav.find('.js-megamenu__heading');
			$navToggle = $('.js-header__nav-toggle');
			elementsInit = true;
		}
	},

	_initSubscriptions: function () {
		subscribe('/nav/searchToggle', module._closeNav);
		subscribe('/nav/langToggle', module._closeNav);
	},

	_initEvents: function () {
		$navToggle.on('click', module._toggleSmallMenu);
		$navLinks.on('click', module._openSubMenu);
	},

	unbind: function () {
		unsubscribe('/nav/searchToggle', module._closeNav);
		unsubscribe('/nav/langToggle', module._closeNav);
		$navToggle.off('click', module._toggleSmallMenu);
		$navLinks.off('click', module._openSubMenu);
		module._resetMenu();
	},

	// close primary nav menu if open
	_closeNav: function () {
		if ($navToggle.hasClass(classes.expanded)) {
			$navToggle.trigger('click');
		}
	},

	// publish nav toggle event
	// show/hide primary nav menu dependant on current state
	// toggle class on menu button
	_toggleSmallMenu: function (event) {
		event.preventDefault();
		publish('/nav/navToggle');
		$nav.toggleClass(classes.expanded + ' ' + classes.collapsed);
		$navToggle.toggleClass(classes.expanded);

		if ($nav.hasClass(classes.collapsed)) {
			$nav.find('.' + classes.expanded).removeClass(classes.expanded);
		}
	},

	_openSubMenu: function (event) {
		event.preventDefault();
		var $link = $(this);
		var $icon = $link.find(selectors.navIcon);
		var $item = $link.closest(selectors.navItem);

		if (!$item.hasClass(classes.expanded)) {
			$nav.find('iconf-arrow-up').removeClass('iconf-arrow-up').addClass('iconf-arrow-down');
			$nav.find('.' + classes.expanded).removeClass(classes.expanded);
		}

		$item.toggleClass(classes.expanded);
		$icon.toggleClass('iconf-arrow-up iconf-arrow-down');
	},

	// collapse nav and any expanded children
	_resetMenu: function () {
		$nav.removeClass(classes.expanded);
		$navItems.removeClass(classes.expanded);
		$navToggle.removeClass(classes.expanded);
	}
};

var NavSmall = {
	init: module.init,
	unbind: module.unbind,
};

export { NavSmall };