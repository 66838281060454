import $ from 'jquery';
import { publish, subscribe, unsubscribe } from 'Util/pubsub';
import * as Core from 'Util/core';
import 'hoverIntent';

var $nav;
var selectors;
var classes;

var navHeight;
var navPos;
var topPos;

var $window;
var $body;
var $anchorLinks;
var $navWithMenus;

var module = {

	init: function ($elm, selectorList, classList) {

		$nav = $elm;
		selectors = selectorList;
		classes = classList;

		module._initElements();
		module._setSizes();
		module._initSubscriptions();
		module._initEvents();
	},

	_setSizes: function () {
		navHeight = $nav.outerHeight();
		navPos = $nav.offset().top;
	},

	_initElements: function () {
		$window = $(window);
		$body = $('body');
		$navWithMenus = $nav.find(selectors.navItemMega);
		$anchorLinks = $('.js-anchor-link');
	},

	// Listen for window resize event (listener bound on all.js)
	_initSubscriptions: function () {
		//subscribe('/window/resize', module._setSizes);
		subscribe('/window/scroll', module._stickyNav);
	},

	_initEvents: function () {

		$navWithMenus.hoverIntent({
			over: module._toggleMegaMenuHover,
			out: module._toggleMegaMenuHover,
			interval: 30
		});

		$anchorLinks.on('click', module._processAnchorClick);

		if ($(selectors.noTouch).length) {
			return;
		}

		this._bindTouchEvents();
	},

	_toggleMegaMenu: function (target, click, event) {

		if (click === 'click') {
			event.preventDefault();
		}

		var $thisTarget = $(target);
		var $thisNav = $thisTarget.is(selectors.navItemMega) ? $thisTarget : $thisTarget.closest(selectors.navItemMega);
		var $expanded = $nav.find('.' + classes.expanded);

		if (click && click === 'click' && $expanded.find(selectors.megamenuHeading)[0] !== target) {
			$expanded.removeClass(classes.expanded);
		}

		$thisNav.toggleClass(classes.expanded);

	},

	_bindTouchEvents: function () {

		$navWithMenus.each(function () {
			$(this).find(selectors.megamenuHeading).on('click', module._toggleMegaMenuClick);
		});

		//$('body').on('click', module._handleBodyClick);
	},

	_toggleMegaMenuHover: function () {
		publish('/nav/navToggle');
		module._toggleMegaMenu(this);
	},

	_toggleMegaMenuClick: function (event) {
		publish('/nav/navToggle');
		event.preventDefault();
		//module._toggleMegaMenu(this, 'click', event);
	},

	unbind: function () {
		$anchorLinks.off('click', module._processAnchorClick);
		$navWithMenus.off('click', this._toggleMegaMenu);
		$navWithMenus.off('mouseenter').off('mouseleave');
		$navWithMenus.removeProp('hoverIntent_t');
		$navWithMenus.removeProp('hoverIntent_s');
		unsubscribe('/window/scroll', module._stickyNav);
	},

	_processAnchorClick: function (event) {
		event.preventDefault();

		var hash = this.href.split('#')[1];
		var $anchor = $('#' + hash);

		if ($anchor.length) {
			Core.scrollToElm($anchor, navHeight - 1);
		}
	},

	_handleBodyClick: function (e) {
		var $target = $(e.target);

		if (!$target.closest(selectors.nav).length && $nav.find('.' + classes.expanded).length) {
			$nav.find('.' + classes.expanded).removeClass(classes.expanded);
		}
	},

	_stickyNav: function () {
		var newTopPos = $window.scrollTop();

		if (newTopPos > navPos) {
			$nav.addClass(classes.sticky);
			$body.css('padding-top', navHeight);
		} else {
			$nav.removeClass(classes.sticky);
			$body.css('padding-top', 0);
		}
		topPos = newTopPos;
	}
};

var NavLarge = {
	init: module.init,
	unbind: module.unbind,
};

export { NavLarge };
