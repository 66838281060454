import $ from 'jquery';
import { subscribe, publish } from 'Util/pubsub';

var Positions = {
	RIGHT: 0,
	LEFT: 1
}

var module = {

	init: function () {
		module._initEvents();
		module._initSubscriptions();
	},

	_initEvents: function () {
		$(document)
			.on('click', module._processOutsideClick)
			.on('click', '.js-tooltip__trigger, .js-tooltip__close', module._processClick);
	},

	_initSubscriptions: function () {
		subscribe('tooltip/open', module._open);
		subscribe('tooltip/close', module._close);
		subscribe('tooltip/closeAll', module._closeAll);
	},

	_processOutsideClick: function (e) {
		var $this = $(e.target);

		if (!$this.closest('.js-tooltip').length) {
			publish('tooltip/closeAll');
		}
	},

	_processClick: function (e) {
		var $tooltip = $(e.target).closest('.js-tooltip');

		if ($tooltip.hasClass('is-open')) {
			publish('tooltip/close', {
				tooltip: $tooltip
			});
		} else {
			publish('tooltip/open', {
				tooltip: $tooltip
			});
		}

		return false;
	},

	_getPosition: function ($tooltip) {
		$tooltip = $tooltip.closest('.js-tooltip');
		var $body = $tooltip.find('.js-tooltip__body'),
			position = Positions.RIGHT,
			isShown = $tooltip.hasClass('is-open'),

			gap,
			rightMaxWidth,
			leftMaxWidth;

		if (!isShown) {
			$tooltip.addClass('is-open');
		}

		$tooltip.removeClass('c-tooltip--left');

		if ($body.data('tooltip-max-width')) {
			$body.css('max-width', $body.data('tooltip-max-width'));
		}

		if (($body.offset().left + $body.outerWidth()) > $(window).width()) {
			// Would go off the right side of the screen

			// Distance between tooltip and trigger
			// Check margin-left because left class is always removed before this
			gap = parseInt($body.css('margin-left'), 10);

			if ($body.offset().left > $body.outerWidth() + $tooltip.find('.js-tooltip__trigger').outerWidth() + 2 * gap) {
				// Won't go off the left side of the screen
				position = Positions.LEFT;
			} else {
				// Would go off either side of the screen, so restrict the width

				if (!$body.data('tooltip-max-width')) {
					$body.data('tooltip-max-width', $body.css('max-width'));
				}

				// Current width, minus the amount it would stick out the right side
				rightMaxWidth = $body.outerWidth() - ($body.offset().left + $body.outerWidth() - $(window).width());

				leftMaxWidth = $body.outerWidth() - ($body.outerWidth() + $tooltip.find('.js-tooltip__trigger').outerWidth() + 2 * gap - $body.offset().left);

				if (rightMaxWidth > leftMaxWidth) {
					$body.css('max-width', rightMaxWidth + 'px');
				} else {
					$body.css('max-width', leftMaxWidth + 'px');
					position = Positions.LEFT;
				}
			}
		}

		if (!isShown) {
			$tooltip.removeClass('is-open');
		}

		return position;
	},

	_open: function (data) {
		var $tooltip = $(data.tooltip).closest('.js-tooltip'),
			position = module._getPosition($tooltip);

		$tooltip.addClass('is-open');

		if (position === Positions.LEFT) {
			$tooltip.addClass('c-tooltip--left');
		} else {
			$tooltip.removeClass('c-tooltip--left');
		}
	},

	_close: function (data) {
		var $tooltip = $(data.tooltip).closest('.js-tooltip');

		$tooltip.removeClass('is-open');
	},

	_closeAll: function (data) {
		var $container = $(data && data.container);

		if ($container && $container.length) {
			// Close all tooltips within this container
			$container.find('.js-tooltip.is-open').removeClass('is-open');
		} else {
			// Close all tooltips everywhere
			$('.js-tooltip.is-open').removeClass('is-open');
		}
	},
};

var Tooltip = {
	init: module.init
};

export { Tooltip };