import $ from 'jquery';
import { subscribe, unsubscribe, publish } from 'Util/pubsub';

import { MediaQueries } from 'Util/mediaqueries';
import * as Keybinding from 'Util/keybinding';
import { NavSmall } from 'App/nav/small';
import { NavLarge } from 'App/nav/large';

var selectors = {
	nav: '.js-navigation',
	navItem: '.js-navigation__item',
	navIcon: '.js-navigation__icon',
	navItemMega: '.js-navigation__item--megamenu',
	megamenuHeading: '.js-megamenu__heading',
	noTouch: '.no-touch'
};

var classes = {
	expanded: 'is-expanded',
	collapsed: 'is-collapsed',
	sticky: 'is-sticky'
};

var $nav;
var $search;
var $searchToggle;
var $langToggle;
var $lang;

var module = {

	init: function () {

		module._initElements();

		if ($nav.length) {
			module._initSubscriptions();
			module._initEvents();
			module._initMediaQueries();
		}
	},

	_initElements: function () {
		$nav = $(selectors.nav);
		$searchToggle = $('.js-header__search-toggle');
		$search = $('.js-header__search');
		$langToggle = $('.js-header__lang-toggle');
		$lang = $('.js-header__lang');
	},

	_initSubscriptions: function () {
		subscribe('/nav/navToggle', module._closeSearch);
		subscribe('/nav/langToggle', module._closeSearch);
		subscribe('/nav/navToggle', module._closeLang);
		subscribe('/nav/searchToggle', module._closeLang);
	},

	_initEvents: function () {
		$searchToggle.on('click', module._toggleSearch);
		$langToggle.on('click', module._toggleLang);

		Keybinding.bind('?', module._focusSearch);
	},

	// publish search toggle event
	// show/hide site search dependant on current state
	// toggle class on search button
	_toggleSearch: function (event) {
		event.preventDefault();
		publish('/nav/searchToggle');
		$search.toggleClass(classes.expanded);
		$searchToggle.toggleClass(classes.expanded);

		if ($search.hasClass(classes.expanded)) {
			$search.find('.js-header__search-input').focus();
		}
	},

	// focus on the search element
	_focusSearch: function (event) {
		$search.find('.js-header__search-input').focus();
	},

	// close site search if open
	_closeSearch: function () {
		if ($searchToggle.hasClass(classes.expanded)) {
			$searchToggle.trigger('click');
		}
	},

	// publish language toggle event
	// show/hide site search dependant on current state
	// toggle class on language button
	_toggleLang: function (event) {
		event.preventDefault();
		publish('/nav/langToggle');
		$lang.toggleClass(classes.expanded);
		$langToggle.toggleClass(classes.expanded);
	},

	// close language picker if open
	_closeLang: function () {
		if ($langToggle.hasClass(classes.expanded)) {
			$langToggle.trigger('click');
		}
	},

	_initMediaQueries: function () {

		MediaQueries.register([
			{
				// Bind Small Nav
				queries: MediaQueries.queries['megamenu--small'],
				shouldDegrade: false,
				match: function () {
					NavSmall.init($nav, selectors, classes);
				},
				unmatch: function () {
					NavSmall.unbind($nav, selectors, classes);
				}
			}, {
				// Bind Large Nav
				queries: MediaQueries.queries['megamenu--large'],
				shouldDegrade: true,
				match: function () {
					NavLarge.init($nav, selectors, classes);
				},
				unmatch: function () {
					NavLarge.unbind($nav, selectors, classes);
				}
			}
		]);
	}
};

var NavLoad = {
	init: module.init
};

export { NavLoad };