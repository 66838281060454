/* app/ui/antidote/antidote-custom */

import $ from 'jquery';

let $containers;
let antidote;
let screening_session_id;

let module = {
	init: function () {

		$containers = $('.js-antidote-medical-screening');

		if ($containers.length) {
			module._initContainers();
		}
	},

	_initContainers: function () {

		// loop through all the containers and find the url and init antidote
		$containers.each(function () {
			// Init the medical library
			// For all options, check the API Library page in the library
			let url = $(this).data('url');
			let button = $(this).next('.js-start-screening');

			// Check if the url is set and init the library and events
			if ((url != null) || (typeof url !== 'undefined')) {

				antidote = Antidote.init({
					modal: true,
					url: url,
					container: this
				});

				button.on('click', module._startScreening);
			}
		});
	},

	_startScreening(event) {
		let button = $(event.target);
		// Get the person id from the data attribute
		let personId = button.data('personid');

		// Make a request to your server in any way you prefer this
		// is needed as you will only be able to init a screening
		// session with your *private* credentials.
		//
		// Keep this key private, do not use it in any browser requests or
		// store it in any javascript code that gets sent to the browser.

		if ((personId != null) || (typeof personId !== 'undefined')) {

			$.ajax({
				type: 'POST',
				url: `/api/medical-screening/start/${personId}`,
				dataType: 'json'
			})
				.done((res) => {
					// After the request to your server, you will be given a
					// session id by the medical screening api this needs
					// to be passed to the javascript library.
					//console.log('Starting Screening Session: ', res);
					screening_session_id = res.screening_session_id;
					antidote.start(screening_session_id, module._finishScreening);
				})
				.fail((error) => {
					console.log(error);
					console.log('There was a failure in retrieving the screening session id')
				});
		}
	},

	_finishScreening() {
		// Now we're in here, all you will need to do is make a call back to your server
		// that will deal with actually making the screening session is finished
		// and receiving a risk score back from the service.

		console.log('Triggered finish screening')

		$.ajax({
			type: 'POST',
			url: `/api/medical-screening/finish/${screening_session_id}`,
			dataType: 'json'
		})
			.done((res) => {
				console.log(res);
				location.reload();
			})
			.fail(() => {
				console.log('There was a failure in finishing the screening session')
			});
	}

};

const AntidoteCustom = {
	init: module.init
};

export { AntidoteCustom };