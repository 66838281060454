import $ from 'jquery';
import 'expandcollapse';

var Accordion;
var $accordions;
var $expandCollapseAll;

var module = {

	init: function () {
		$accordions = $('.js-expand-collapse');
		$expandCollapseAll = $('.js-expand-collapse__all');
		$accordions.expandCollapse({
			item: '.js-expand-collapse__item',
			header: '.js-expand-collapse__header',
			content: '.js-expand-collapse__body'
		});

		module._initEvents();
	},

	_initEvents: function () {
		$('.js-expand-collapse__all').on('click', { $obj: this }, module._processAllEvent);
		$accordions.on('expandcollapse.clicked', { $obj: this }, module._processHeaderClick);
		$accordions.on('expandcollapse.clicked', { $obj: this }, module._checkExpandedStatus);
		$accordions.on('expandcollapse.opened', { $obj: this }, module._triggerLazyload);
	},

	_processAllEvent: function (event) {

		event.preventDefault();
		var eventToTrigger;
		var $thisButton = $(this);
		var $obj = event.data.$obj;

		eventToTrigger = $thisButton.is('.is-expanded') ? 'expandcollapse.close' : 'expandcollapse.open';
		$obj._triggeraccordionEvents(eventToTrigger);
	},

	_processHeaderClick: function () {
		var $thisHeader = $(this);
		if (typeof Modernizr !== 'undefined' && Modernizr.csstransitions) {
			return;
		}
		$thisHeader.find('.js-icon').toggleClass('iconf-arrow-down iconf-arrow-up');
	},

	_checkExpandedStatus: function () {
		var $thisAccordion = $(this).closest('.js-expand-collapse__item');
		var $openAccordions = $accordions.filter('.is-expanded').length;

		if ($thisAccordion.hasClass('is-collapsed') && ($accordions.length - $openAccordions) === 1) {
			module._updateButton($expandCollapseAll);
		}
		if ($thisAccordion.hasClass('is-expanded') && ($accordions.length == $openAccordions)) {
			module._updateButton($expandCollapseAll);
		}
	},

	_triggerLazyload: function (e) {
		var $thisAccordion = $(e.target).closest('.js-expand-collapse__item');

		if ($thisAccordion.data('lazyload-triggered')) {
			return;
		}
		$thisAccordion.data('lazyload-triggered', true);

		$thisAccordion.find('.js-lazy-auto').trigger('appear');
	},

	_updateButton: function ($thisButton) {
		var buttonText;
		$accordionHeader = $('.js-expand-collapse__header');
		$actionAccordionHeader = $('.pod--action').find('.js-expand-collapse__header');
		$thisButton.toggleClass('is-expanded is-collapsed');
		$thisButton.find('.iconf-plus, .iconf-minus').toggleClass('iconf-plus iconf-minus');
		buttonText = $thisButton.is('.is-expanded') ? 'Close all' : 'Open all';
		$thisButton.find('.js-expand-collapse__text').text(buttonText);


		if ($('.pod--action').find('.js-expand-collapse__header:contains("Click to see more")').length > 0) {
			$actionAccordionHeader.text("Close");
		} else {
			$actionAccordionHeader.text("Click to see more");
		}
	},

	_triggeraccordionEvents: function (eventToTrigger) {
		$accordions.each(function () {
			var $thisAccordion = $(this);
			if (!($thisAccordion.is('.is-expanded') && eventToTrigger === 'expandcollapse.open') && !($thisAccordion.is('.is-collapsed') && eventToTrigger === 'expandcollapse.close')) {
				$thisAccordion.trigger(eventToTrigger);
			}
		});
	}

};

var Accordion = {
	init: module.init
};

export { Accordion };