import { LazyLoad } from 'Util/lazyload';
import { NavLoad } from 'App/nav/load';
import { Tooltip } from 'App/tooltip/tooltip';
import { Summary } from 'App/form/summary';
import { Accordion } from 'App/accordion/accordion';
import { Social } from 'App/social/share';
import { AntidoteCustom } from 'App/antidote/antidote.custom';

LazyLoad.init();
NavLoad.init();
Tooltip.init();
Accordion.init();
Social.init();

if (document.querySelector('.js-questionnaire')) {
	import(/* webpackChunkName: "Questionaire" */ 'App/form/questionnaire').then(({ Questionnaire }) => {
		Questionnaire.init();
	});
}

if (document.querySelector('.js-summary')) {
	Summary.init();
}

if (document.querySelectorAll('.js-antidote-medical-screening')) {
	AntidoteCustom.init();
}

// Sanitize HTML
import sanitizeHtml from 'sanitize-html';

const body = document.querySelector('body');

sanitizeHtml(body);