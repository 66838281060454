import $ from 'jquery';
import { scrollToElm } from 'Util/core';

import { Validate } from 'App/form/validate';
import { subscribe } from 'Util/pubsub';

var $summary;

function Person(id, title, fn, ln) {
	this.PersonId = parseInt(id, 10);
	this.Title = title;
	this.FirstName = fn;
	this.LastName = ln;
}

var module = {
	init: function () {

		$summary = $('.js-summary');

		Validate.init();
		module._initSubscriptions();
		module._initEvents();
	},

	_initSubscriptions: function () {
		subscribe('/form/' + $summary.attr('id') + '/valid', module._submitSummary);
	},

	_initEvents: function () {
		//$summary.on('customvalidation', module._customValidation);
	},

	_submitSummary: function () {
		var data = $summary.serialize();
		var dataArray = data.split('&');
		var summaryData = {};

		module._showLoadingButton($summary);

		for (var i = 0; i < dataArray.length; i++) {
			var field = dataArray[i].split('=');
			summaryData[field[0]] = decodeURIComponent(field[1]);

			if (summaryData[field[0]].indexOf('+')) {
				summaryData[field[0]] = summaryData[field[0]].replace(/\+/g, ' ');
			}
		}

		// Customer (Card Holder)
		summaryData.Customer = new Person(
			0,
			summaryData.CustomerTitle,
			summaryData.CustomerFirstName,
			summaryData.CustomerLastName
		);

		summaryData.Customer.Address = summaryData.Address;
		summaryData.Customer.Suburb = summaryData.Suburb;
		summaryData.Customer.City = summaryData.City;
		summaryData.Customer.Postcode = summaryData.Postcode;
		summaryData.Customer.Phone = summaryData.Phone;
		summaryData.Customer.Email = summaryData.Email;

		// Spouse
		summaryData.Spouse = new Person(
			0,
			summaryData.SpouseTitle,
			summaryData.SpouseFirstName,
			summaryData.SpouseLastName
		);

		// Family Members
		var familyMembers = [];
		var $dependants = $('.js-dependant');

		for (var k = 0; k < $dependants.length; k++) {
			var num = k + 1;
			var member = new Person(
				summaryData['ChildPersonId' + num],
				summaryData['ChildTitle' + num],
				summaryData['ChildFirstName' + num],
				summaryData['ChildLastName' + num]
			);
			familyMembers.push(member);
		}

		summaryData.FamilyMembers = familyMembers;

		$.ajax({
			type: 'POST',
			url: $summary.attr('action'),
			data: JSON.stringify(summaryData),
			contentType: 'application/json',
			success: function (response) {
				if (response.NextStepUrl) {
					var url = response.NextStepUrl;
					window.location.replace(url);
				} else if (Array.isArray(response)) {
					module._showErrors(response);
				} else {
					console.error(response);
				}
			},
			error: function (error) {
				window.location.replace('error');
			}
		});
	},

	_showErrors: function(errList) {
		console.error(errList);

		$summary.find('.js-validate-field.has-error').removeClass('has-error');
		$summary.find('.js-validate-field-validation').empty();

		for (var i = 0, l = errList.length; i < l; i++) {
			var err = errList[i];
			var $input = $summary.find('input[name="' + err.Name + '"]');
			if ($input) {
				var $field = $input.closest('.js-validate-field');
				var $message = $field.find('.js-validate-field-validation');

				$field.addClass('has-error');
				$message.text(err.Message);
			}
		}

		// reset submission button
		var $submit = $summary.find('.js-loader-btn');
		$submit.html($submit.data('initial'));
		$submit.attr('disabled', false);

		scrollToElm($summary.find('.js-validate-field.has-error').first(), 100);
	},

	// Disabled submit button
	// Add loading text to button - this will be animated by CSS
	_showLoadingButton: function ($form) {
		var $submit = $form.find('.js-loader-btn');
		$submit.data('initial', $submit.html());

		if ($submit.length) {
			$submit.html('<span>L</span><span>O</span><span>A</span><span>D</span><span>I</span><span>N</span><span>G</span>');
			$submit.attr('disabled', true);
		}
	}
};

var Summary = {
	init: module.init
};

export { Summary };